<!-- Detalle Presupuestos Expedientes -->

<template>
  <div class="exp_pres_detalle" v-if="schema">
    
      <!-- Contenido -->
      <div class="conflex">
      

        <div class="columna" >
          <div class="cab" style="width:750px">DETALLE</div>
          <v-sheet :elevation="4">
          <!-- Grid -->
          <base_Fgrid style="width:750px"
                :padre="stName"
                :Entorno="Entorno"
                @onEvent="event_Grid"
                :items-per-page=30
                :show-expand="!ordenando"
                dense>

            <!-- Slot botonera Top -->
            <template v-slot:top_btns="{ Sitem }">
              <div style="display:flex;justify-content:space-between;padding:10px 0 0 10px;">
                <btracombi
                   v-show="is_padreEdit && ordenando==false"
                  :btra_permisos="schema.btra"
                  :btra_particular="Entorno.btra"
                  btra_modulo="top"
                  @onEvent="event_btra_Top({ accion: $event.accion, row: Sitem })">                
                </btracombi>
                <!-- boton ordenacion -->
               <v-btn v-if="is_padreEdit && !is_hijoEdit && !expandedItem"
                        color="blue-grey lighten-1"
                        dark
                        small
                        title="Orden de lineas"
                        style="margin-right:10px"
                        @click="clickOrdenar"
                        >
                         {{ordenarTitulo}}
                <v-icon> mdi-swap-vertical-bold</v-icon>
                </v-btn>
              
              </div>
            </template>

            <!-- Slot campos del grid menos el de acciones -->
            <template v-slot:name_prod_id="{ Sitem}">
                {{ Sitem.name_prod_id }}
              <div
                v-if="Sitem.namea >''"
                class="light-blue--text text--darken-3">
                {{ Sitem.namea }}
              </div>
            </template>
          <template v-slot:sp={Sitem}>
           <div style="color:red">
             {{Sitem.sp=='1'?'PE':''}}
            </div>
          </template>
          <template v-slot:sup={Sitem}>
           <div style="color:green">
             {{Sitem.sup=='S'?'S':''}}
            </div>
          </template>
<!-- orden -->
         <template v-slot:orden={Sitem}>
           <div style="color:green">
             <v-icon @click="ordenDown(Sitem)"  color="green">mdi-arrow-down-bold</v-icon>
             <v-icon @click="ordenUp(Sitem)"    color="green">mdi-arrow-up-bold</v-icon>
            </div>
          </template>





            <!-- Slot panel expansible -->
            <template v-slot:expansible_ctrls="{ Sitem }">              
              <exp_pres_detalle_XM style="width:745x"
                :padre="stName"
                :accion="accion"
                :accionRow="Sitem"
                :disparo="disparo"
                @onEvent="event_expansible">              
              </exp_pres_detalle_XM>
            </template>
          </base_Fgrid>
           </v-sheet>
        </div>
        <!-- fin coluna grid -->



         <div v-show="is_hijoEdit" class="columna" style="padding-left:8px" >
          <productos :edicion="is_hijoEdit"
          @onEvent="event_productos"/>
        </div>
         
      
      
</div>
 </div>
 
</template>

<script>
import { mixinFinder } from "@/mixins/mixinFinder.js";
/* import { mixinDetalle } from "@/mixins/mixinDetalle.js"; */
import plugs from "@/common/general_plugs";
const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base");
const btracombi = () => plugs.groute("btracombi.vue", "comp");
const productos = () => plugs.groute("productos.vue", "comp");
const exp_pres_detalle_XM = () => plugs.groute("exp_pres_detalle_XM.vue", "comp");

export default {
  mixins: [mixinFinder],
  components: { base_Fgrid, btracombi, productos, exp_pres_detalle_XM },
  props: {
    padre: { type: String, default: "" },
    id: { type: [Number, String], default: 0 },
    disparoFinder: { type: Boolean, default: false },
    componenteTipo: { type: String, default: "FM" },
    Entorno: {
      type: Object,
      default: function() {
        return JSON.parse(JSON.stringify(this.$cfg.base.F));
      }
    }
  },

  data() {
    return {
      schema: null,
      api: "exp_presLin_F",
      stName: "stFexp_presLin_F",
      disparo: false,

      headers: {},
      accion:"",
      accionRow:{},

      // vars ordenacion
      ordenando:false,
      ordenarTitulo:"ordenar lineas",
      // incluido en mixinFinder data
      // expandedItem:false
    };
  },

  //
  methods: {
       // recibo evento cuando se abre o cierra expansible
    eventExpansion(evt){
     this.expandedItem=evt.value==true?true:false;
    },

    // recibo un evento cuando hay un detalle modificado
    // al guardarlo se supone que se cierra el expansible
    // y pongo su chivato a false

    // lo quito de aqui ->ojo conflicto de metodo en mixinFinder aC1ado la linea en mixinfinder
    // con el mismo nombre de metodo
    //event_expansible(){
    //  this.expandedItem=false;
   // },
    get_records_fin() {
      this.ordenando=true;
      this.expandedItem=false;
      this.clickOrdenar();
    }, 

    clickOrdenar() {
      this.ordenando=!this.ordenando;
      this.ordenarTitulo=this.ordenando? "fin ordenar" : "ordenar lineas";
      this.Entorno.grid.headers=this.ordenando?this.set_headers('orden'):this.set_headers('header');
    },

    ini_data() {
      console.log("DEV " + this.api + " ********************** (ini_data)");

      // guardo Schema del store en variable local
      this.schema = this.$store.state[this.stName].schema;

      // le paso el indice del record detalle que coge del Mto
      this.$store.commit("data2State", {
        modulo: this.stName,
        prop: "no_getRecordsAPI",
        value: 1
      });

      // configuro Entorno
      this.Entorno.grid.autoload = true;
      this.Entorno.grid.row.expansible = true;
      this.Entorno.grid.bind["items-per-page"] = -1;
      this.Entorno.header.header = false;
     

      this.headers = {
        header: [
          { text: "CONCEPTO", value: "name_prod_id", width: "50%", slot:true },
  
          { text: "SUP", value: "sup", style: "color:green", slot:true },
          { text: "UD", value: "und",align:"center", style: "text-align:right" },
          //{ text: "P/T", value: "grup" },

          { text: "IVA", value: "iva", style: "text-align:right" },
          { text: "PRECIO", value: "pvp", style: "text-align:right" },
          {
            text: "IMP",
            value: "imp",
            style: "font-weight:bold;text-align:right;color:#01579B"
          }
        ],
       orden:[
        
          { text: "Ordenar", value: "orden", width: "15%", slot:true },
          { text: "CONCEPTO", value: "name_prod_id", width: "50%", slot:true },
          { text: "PE", value: "sp", slot:true},
          { text: "SUP", value: "sup", style: "color:green", slot:true },
          { text: "UD", value: "und",align:"center", style: "text-align:right" },
          //{ text: "P/T", value: "grup" },

          { text: "IVA", value: "iva", style: "text-align:right" },
          {
            text: "IMP",
            value: "imp",
            style: "font-weight:bold;text-align:right;color:#01579B"
          }
        ]
      };
      
    
 
      this.Entorno.grid.headers = this.set_headers();
    },

    // eventos de productos
    event_productos(evt) {
      console.log("*** event_productos pres_funes_detalle. Accion: ", evt, " ***");

      // Paso nombre de producto a control del xm aC1adiendole la clave al literal
      this.$store.state[this.$store.state[this.stName].hijo].schema.ctrls.prod_id.value = evt.item.id;        
      this.$store.state[this.$store.state[this.stName].hijo].schema.ctrls.name_prod_id.value = "(" + evt.item.clave + ") " + evt.item.name;
            
      // Paso al record del mx la clave porque no tiene ctrl
      this.$store.state[this.$store.state[this.stName].hijo].record.clave =   evt.item.clave;
    },
   ordenDown(item){
      this.$emit('onEventOrden',{accion:'down', item:item});      
      this.$store.commit(this.stName + '/data2State', { prop:'hijoChanges', value:true});
    },
   ordenUp(item){
      this.$emit('onEventOrden',{accion:'up', item:item});
      this.$store.commit(this.stName + '/data2State', { prop:'hijoChanges', value:true});
    },
    // configuraciC3n particular de los campos del grid
    get_fieldGrid(item, header) {
      if (typeof item[header.value] === "undefined") return;
      if (header.value === "und") return Math.trunc(item["und"]);
      /* if (header.value === "und") return Math.trunc(item["und"]);*/
      if (header.value === "sup")
        return item["sup"] == "1" || item["sup"].toUpperCase() === "S"
          ? "SUP"
          : "";
      if (header.value === "sp")
        return item["sp"] == "1" || item["sp"].toUpperCase() === "S"
          ? "PE"
          : "";
      return item[header.value];
    }

    /* 
      //
      cget_sp(item, dato)
      {
        return dato=== "1" || dato.toUpperCase()=== "S"? "S" : "N";
        //this.schema.ctrls.sp.value= dato=== "1" || dato.toUpperCase()=== "S"? "S" : "N";
        //console.log('cget_sp: ', item, dato);
      },

      //
      cset_sp(item, dato)
      {
        console.log('CSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SPCSET_SP', item, dato);
        return dato.toUpperCase()=== "S"? "1" : "0";
        //console.log('cset_sp: ', item, dato);
      } */
  }
};
</script>

<style scoped>
.v-data-table td,
.v-data-table th {
  padding: 5 !important;
}
</style>
